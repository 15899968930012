<template>
    <simple-table
      :store="store"
      :icon="icon"
      :title="title"
      :namespace="namespace"
    ></simple-table>
  </template>
    
  <script setup>
  import SimpleTable from "@/components/core/tables/SimpleTable.vue";
  import { usePostStore } from '@/stores/post'
  
  const namespace = 'post'
  const icon = 'mdi-post'
  const title = 'Post'
  const store = usePostStore()
  </script>
  