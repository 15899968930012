<template>
    <form-wizard
      :form-data="formData"
      :namespace="namespace"
      :title="pageTitle"
      :icon="icon"
      :store="store"
    ></form-wizard>
  </template>  
  <script setup>
  import FormWizard from "@/components/core/form/FormWizard.vue";
  import { usePostStore } from '@/stores/post'
  import { useRoute } from 'vue-router'
  import * as yup from 'yup';
  const router = useRoute();
  const icon = router.meta.icon || "";
  const pageTitle = router.meta.title || "";
  const namespace = router.meta.namespace || "";
  const store = usePostStore()
  const {getItem} = store
  await getItem(router.params.id)
  const { item } = store
  const { title, content, is_published,image,} = item;
  const imageName = image!==''? [new File([], image.split(/(\\|\/)/g).pop())] : []
  const formData = [
  {
            text: "Title*",
            placeholder: "Title*",
            fieldName: "StandardTextField",
            value: title,
            name: "title",
            class: "v-col-sm-12 v-col-md-12",
            rules:  yup.string().required()
          },
          {
            text: "Content*",
            value: content,
            placeholder: "Content*",
            name: "content",
            fieldName: "TextEditorField",
            required: true,
            class: "v-col-12 v-col-sm-12 v-col-md-12",
            rules:  ""
          },
          {
            text: "Image",
            value: imageName,
            imagePath: image,
            placeholder: "Image",
            name: "image",
            multiple:true,
            filePath: 'posts/',
            fileNames: [],
            fieldName: "FileUploadSimpleField",
            required: true,
            class: "v-col-sm-12 v-col-md-12",
            rules:  ""
          },
          {
            text: "Is Published?:",
            placeholder: "Is published?",
            fieldName: "SwitchField",
            value: is_published,
            trueValue: 1,
            falseValue:0,
            name: "is_published",
            class: "v-col-12 v-col-sm-12 v-col-md-12",
            hint:"If you select this option, the product will be displayed on the blog page",
            rules:  ""
          },
         
          
         
        ];
  
  </script>