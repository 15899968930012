<template>
  <v-row
    align="center"
    no-gutters
    class="justify-end"
  >
    <v-col cols="12" >
      <v-divider :color="item.color"></v-divider>
      <v-card-title>{{ item.text }}</v-card-title>
    </v-col>
  </v-row>
</template>
<script setup>
const props = defineProps({
item: Object
})
</script>