import View from "@/views/admin/Post.vue";
import Index from "@/components/admin/posts/List.vue";
import Create from "@/components/admin/posts/Create.vue";
import Edit from "@/components/admin/posts/Edit.vue";
export default [
    {
        path: "/posts",
        name: "posts",
        component: View,
        title: "Posts",
        redirect: {
            name: "list-post"
        },
        children: [
            {
                path: "list",
                name: "list-post",
                meta: {
                    icon: "mdi-post"
                },
                component: Index
            },
            {
                path: "create",
                name: "create-post",
                meta: {
                    storeAction: "post/insertItem",
                    namespace: "post",
                    store: "post",
                    title: "Create post",
                    icon: "mdi-post"
                },
                component: Create
            },
            {
                path: "edit/:id",
                name: "edit-post",
                meta: {
                    storeAction: "post/updateItem",
                    namespace: "post",
                    store: "post",
                    title: "Edit post",
                    icon: "mdi-post"
                },
                component: Edit
            }
        ]
    }
];
