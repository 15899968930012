<template>
    <QuillEditor theme="snow" ref="editor" toolbar="full" contentType="html" v-model="value" :placeholder="item.placeholder" :label="item.text"
        :error-messages="errorMessage" :name="item.text" @update:content="handleChange" @blur="handleBlur" @ready="setContent" />
</template>
<script setup>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { useField } from 'vee-validate';
import { ref } from 'vue'
const props = defineProps({
    item: Object
})
const {
    value,
    errorMessage,
    handleBlur,
    handleChange
} = useField(props.item.name, props.item.rules);

const editor = ref(null)
const setContent = () => {
    editor.value.setHTML(props.item.value)
}
</script>