<template>
    <v-toolbar
    theme="dark"
    color="primary"
     rounded
     ><v-toolbar-title><v-icon>{{icon}}</v-icon> {{ title }}</v-toolbar-title>
       <v-spacer></v-spacer>
       <v-text-field
         v-model="pagination.search"
         append-inner-icon="mdi-magnify"
         label="Search"
         single-line
         hide-details
         density="compact"
         variant="solo-filled"
         flat
         class="pa-3 search-bar"
       >
       <template v-slot:prepend>
        <v-combobox
          :items="pagination.searchItems"
          label="Search by"
         variant="solo-filled"
         flat
         hide-details
         chips
          v-model="pagination.searchBy"
          solo
        ></v-combobox>
       </template>
    </v-text-field>
       <v-btn v-if="actionRoutes" variant="tonal" link :to="{name:createRoute}">
         Add new
         <v-icon right>mdi-plus-circle-outline</v-icon>
       </v-btn>
     </v-toolbar>
  <v-data-table-server
     :headers="headers"
     :items-length="total"
     v-model:items-per-page="pagination.limit"
     v-model:page="pagination.page"
     v-model:sort-by="pagination.orderByArray"
     :search="pagination.search"
     :must-sort="true"
     hover
     :items="items"
     :loading="loading"
     loading-text="Loading... Please wait"
     class="elevation-1"
     @update:options="getItems"
   >
   <template v-slot:item.image="{ item }">
     <v-img :src="item.image" width="128" class="pa-3 mx-auto" cover></v-img>
       </template>
       <template v-slot:item.free="{ item }">
     <div>{{ (item.free===1)?"Yes":"No" }}</div>
     </template>
       <template v-slot:item.is_popular="{ item }">
     <div>{{ (item.is_popular===1)?"Yes":"No" }}</div>
     </template>
     
     <template v-slot:item.is_published="{ item }">
     <div>{{ (item.is_published===1)?"Yes":"No" }}</div>
     </template>
   <template v-slot:item.description="{ item }">
     <div class="truncate-text">{{ item.description }}</div>
     </template>
   <template v-slot:item.content="{ item }">
     <div class="truncate-text" v-html="item.content"></div>
     </template>
     <template v-slot:item.user_id="{ item }">
     <v-btn  variant="text" @click="activateUserDialog(item.user_id)" color="primary"><v-icon small start >mdi-account</v-icon>User</v-btn>
     </template>
     <template v-slot:item.usermix_id="{ item }">
     <v-btn  variant="text" @click="activateUsermixDialog(item.usermix_id)" color="primary"><v-icon small start >mdi-heart</v-icon>Usermix</v-btn>
     </template>
   <template v-slot:item.actions="{ item }">
         <v-icon small class="mr-2" @click="editItem(item.id)">mdi-pencil</v-icon>
         <v-icon small @click="activateDeleteDialog(item.id)">mdi-delete</v-icon>
       </template>
   </v-data-table-server>
   <delete-dialog v-if="props.actionRoutes" ref="deleteDialog" :dialog="dialog" @delete="deleteOnClick()" @cancel-delete="cancelDelete" />
   <user-dialog  v-if="userId" ref="userViewDialog" :dialog="userDialog" @close-user-dialog="closeUserDialog" :user-id="userId"/>
   <usermix-dialog  v-if="usermixId" ref="usermixViewDialog" :dialog="usermixDialog" @close-usermix-dialog="closeUsermixDialog" :usermix-id="usermixId"/>
  </template>
   
   <script setup>
   import DeleteDialog from "@/components/core/dialogs/DeleteDialog.vue";
   import UserDialog from "@/components/core/dialogs/UserDialog.vue";
   import UsermixDialog from "@/components/core/dialogs/UsermixDialog.vue";
  import { storeToRefs } from 'pinia'
  import { useRouter } from 'vue-router'
  import {ref } from 'vue'
  const props = defineProps({
    store: Object,
    title: String,
    icon: String,
    namespace: String,
    actionRoutes: {
    type: Boolean,
    default: true
    },
    })
   const createRoute = 'create-'+ props.namespace
   const editRoute = 'edit-'    + props.namespace
  const router = useRouter();
  const dialog = ref(false)
  const deleteId = ref(null)
  const userDialog =  ref(false)
  const userId = ref(null)
  const usermixDialog =  ref(false)
  const usermixId = ref(null)
  const { items, loading, total, pagination,headers} = storeToRefs(props.store)
  const { getItems } = props.store
let deleteItem;
let deleteOnClick;
let editItem;
  const activateDeleteDialog = (id) => {
   dialog.value = true
    deleteId.value = id
  }
  const activateUserDialog = (id) => {
    userDialog.value = true
   userId.value = id
  }
  const activateUsermixDialog = (id) => {
    usermixDialog.value = true
   usermixId.value = id
  }
  const cancelDelete = () => {
   dialog.value = false
  }
  const closeUserDialog = () => {
    userDialog.value = false
   userId.value = null
  }
  const closeUsermixDialog = () => {
    usermixDialog.value = false
   usermixId.value = null
  }

  if(props.actionRoutes){
    deleteItem = props.store.deleteItem
  
  deleteOnClick = async () => {
   await deleteItem(deleteId.value)
   getItems()
    deleteId.value = null
   dialog.value = false
  }

  editItem = (id) => {
   router.push({ name: editRoute, params: { id: id } });
  };
  
}
  getItems()
  </script>
  <style>
     .truncate-text{
           max-width: 20vw;
           white-space: nowrap;
           overflow: hidden;
           text-overflow: ellipsis;
     }
     .search-bar .v-input__prepend{
           margin-inline-end: 0px;
            min-width: 120px;
     }
  </style>