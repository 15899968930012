import { defineStore } from "pinia";
import { useSnackbarStore } from "@/stores/snackbar";
import { useRouter } from "vue-router";
import PostsApi from "@/api/posts";
const restApi = new PostsApi();
export const usePostStore = defineStore({
  id: "post",
  state: () => ({
    items: [] as any[],
    total: 0,
    item: {},
    loading: false,
    headers: [
      { title: "#ID", key: "id", align: "start", divider: true },
      { title: "Title", key: "title", align: "center" },
      { title: "Slug", key: "slug", align: "center" },
      { title: "Content", key: "content", align: "center" },
      { title: "Image", key: "image", align: "center" },
      { title: "Published", key: "is_published", align: "center" },
      { title: "Actions", key: "actions", align: "center", sortable: false },
    ],
    pagination: {
      page: 1,
      orderByArray: [{ key: "id", order: "desc" }],
      searchBy: "title",
      searchItems: ["title", "slug", "id"],
      orderBy: "id",
      mustSort: true,
      descending: "desc",
      search: "",
      limit: 6,
    },
  }),
  actions: {
    async getItems() {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        this.pagination.orderBy = this.pagination.orderByArray[0].key;
        this.pagination.descending = this.pagination.orderByArray[0].order;
        const { result } = await restApi.getList(this.pagination);
        this.total = result.total;
        this.items = result.data;
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },

    async getPublishedItems() {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        this.pagination.orderBy = this.pagination.orderByArray[0].key;
        this.pagination.descending = this.pagination.orderByArray[0].order;
        const { result } = await restApi.getPublishedList(this.pagination);
        this.total = result.total;
        this.items = result.data;
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },

    async loadMoreItems() {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        const { result } = await restApi.getPublishedList(this.pagination);
        this.total = result.total;
        this.items.push(...result.data);
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },

    async getItem(id: number) {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      const router = useRouter();
      try {
        const { result } = await restApi.getItem(id);
        this.item = result;
      } catch (error) {
        router.push("/404");
      } finally {
        this.loading = false;
      }
    },

    async insertItem(item: any) {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        const cloneItem = structuredClone(item);
        cloneItem.image = item.image[0].name;
        await restApi.insertItem(cloneItem);
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },

    async updateItem(item: { id: number; values: any }) {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        const values = item.values;
        const cloneItem = structuredClone(values);
        cloneItem.image = cloneItem.image[0].name;
        await restApi.updateItem(item.id, cloneItem);
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },

    async deleteItem(id: number) {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        await restApi.deleteItem(id);
        snackbarStore.success("Deleted successfully");
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },
  },
});
